@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Moniters Rafting reguler";
  src: url('fonts/Moniters+Rafting+reguler-172e_400.otf'),
      url('fonts/Moniters+Rafting+reguler_500.otf'),
      url('fonts/Moniters+Rafting+reguler_700.otf');
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-select span {
  left: 40px;
}
.custom-select + label + ul {
  min-height: 50px;
  max-height: 40vh;
}
.map-container {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient {
  overflow: hidden;

}
.pac-container {
  background:white;
  position:absolute;
  margin-top: 0.25rem;
  left:0px;
  overflow:auto;
  overflow-y:auto !important;
  opacity: 1;
  transform-origin:center top;
  transform:scale(1);
  width:175.99px;
  z-index: 99;
  padding:0.75rem;
  min-height: auto;
  max-height: 24rem;
  background-color:#FFFFFF;
  border-radius:0.375rem;
  box-shadow:
     0 0.125rem 0.25rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(99, 115, 129, 0.1);
  font-family:sans-serif;
  font-size:0.875rem;
  font-weight:normal;
  color:rgb(38, 50, 56);
  outline:none;
}
.pac-container::after{
  background-image: none;
  height: 0;
  display: none;
}
.pac-item {
  padding-top: 9px;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-radius: 0.375rem;
  line-height: 1.25rem;
  cursor: pointer;
  user-select: none;
  transition: all;
  background-color: white;
  opacity: 0.8;
  color: #1f2937;
  border-bottom: 0;
  border-top: 0
}

.pac-item:hover {
  background-color: #f3f4f6;
  opacity: 0.8;
  color: #1f2937;
}

.pac-item:focus {
  background-color: #f3f4f6;
  opacity: 0.8;
  color: #1f2937;
  outline: none;
}
.pac-icon-marker {
  display: none
}
.gradient-before:before {
  content: '';
  position: absolute;
  bottom: 0;
  background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
  height: 100%;
  width: 30px;
  right: 25px;
  pointer-events: none;
}
.gradient-after:after {
  content: '';
  position: absolute;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.8) 0%, transparent 100%);
  height: 100%;
  width: 30px;
  left: 25px;
  pointer-events: none;
}
.logo-gradient {
  background: linear-gradient(160deg, #36845B 0%, #3498DB 100%);
  animation: gradient-logo 5s ease infinite;
}
.logo-beat {
  animation: beat 0.75s infinite alternate;
  transform-origin: center;
}

.custom-button {
  --custom-color: var(--custom-button-bg-color);
  --color: var(--color);
  background-color: var(--custom-color);
  color: var(--color);
  transition: all 0.3s ease-in-out;
}

.custom-hover:hover {
  background-color:var(--hover-color);
  color: var(--hover-font-color);
}

.custom-shade {
  background-color: var(--lighter-shade);
  color: var(--color);
  transition: background-color 0.3s;
}

.custom-shade:hover {
  background-color:color-mix(in srgb, var(--lighter-shade) 95%, black);
  color: var(--color);
}

.popover-content {
  box-shadow: 0 0px 30px -5px rgb(0 0 0 / 0.25);
}

/* date */


div#date-modal div.date-container>input{
  height: 0;
  padding: 0
}


input[type="time"]::-webkit-calendar-picker-indicator {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.date-container>input::placeholder {
  color: #323947;
}
.date-container .button-container {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
.title-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  justify-content: space-between;
}
.date-title {
  font-size: 1rem;
  color: rgb(2, 38, 58);
  font-weight: 500;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  text-align: start;
}
.date-container.delete-hidden .delete-date {
  display: none
}

.close-date, .delete-date {
  border-radius: 50%;
}
.close-date:hover, .delete-date:hover {
  background-color: rgb(33 33 33 / 0.1);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.close-date:active, .delete-date:active {
  background-color: rgb(33 33 33 / 0.2);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.date-container>div >div:nth-child(2) {
  height: auto;
  padding: 1rem;
  border: none;
  margin-top: 0;
}

.date-container>div.transition-all.opacity-1 {
  margin: 0;
  box-shadow: 0 0px 30px -5px rgb(0 0 0 / 0.25);
}
.date-container>div >div:nth-child(2) >div:nth-child(2) {
  padding: 0;
}
.date-container>div >div:nth-child(2) >div:nth-child(2)>div{
  padding:0;
  width: 100%;
}

.date-container>div >div:nth-child(2) >div:nth-child(2)>div>div{
  padding: 0;
  width: 100% !important;
}

.date-container>div >div:nth-child(2) >div:nth-child(3){
  padding-top: 1rem;
  padding-bottom: 0;
}
/* div.date-container>div>div:first-child {
  top: -9px;
  border: 0;
} */


div.date-container>div>div:first-child {
  display: none;
}

@media screen and (min-width: 482px)  {
  .page-header.visible:after {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(0,0,0,.5);
    content: "";
    height: 100vh;
    left:0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    top: var(--after-top, 0);
  }

  .date-container>div.transition-all {
    transform: translateY(0);
    width: 400px;
    z-index: 100;
    border-radius: 0.5rem;
    margin-top: 0.625rem !important;
    background: white;
  }
}

@media screen and (max-width: 482px), screen and (max-height: 700px) {
  .date-container>div.transition-all {
    margin-top: 0rem !important;
  }
}

@media screen and (max-height: 430px) {
  .date-container>div.transition-all.opacity-1>div:nth-child(2)>div.flex div.grid:nth-child(2) {
    overflow: auto;
    max-height: 220px;
  }
}
@media screen and (max-width: 481px), screen and (max-height: 700px) {
  .close-date svg, .delete-date svg {
    height: 24px;
    width: 24px;
  }
  div.date-container>div {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index:999;
    position: fixed;
  }
  .date-container>div.transition-all {
    position: fixed;
    transform: translate(0);
    left: 0;
    top: 0;
    margin-bottom: 0;
    max-height: 100vh;
  }
  .date-container>div.transition-all.opacity-1 {
    height: 100%;
    margin-top: 0;
    max-height: 100vh;
    width: 100%;
  }
  .date-container>div >div:nth-child(2) {
    height:100%;
    border-radius: 0;
    margin-top: 0;
    border: none;
  }
  .date-container>div.transition-all {
    width: 100%;
    z-index: 100;
    max-height: calc(100% - 64px);
  }
}

@keyframes beat {
  to {
    transform: scale(1.2);
  }
}

@keyframes gradient-logo {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}